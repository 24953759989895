import Swiper, { Navigation, Pagination } from 'swiper';


const Carousel = (element, slides = 3) =>
  new Swiper(element, {
    slidesPerView: 3,
    spaceBetween: 20,


    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    autoplay: {
   delay: 5000,
    },
    breakpoints: {
    // when window width is >= 320px
    0: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    // when window width is >= 480px
    768: {
      slidesPerView: 2,
      spaceBetween: 0
    },
    // when window width is >= 640px
  1000: {
      slidesPerView: 3,
      spaceBetween: 40
    }
  }

  });

export default Carousel;
